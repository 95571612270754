import Tippy from '@tippyjs/react';

/**
 * @param content
 * @param theme
 * @param delay
 * @param children
 * @param {import('@tippyjs/react').TippyProps} props
 * @returns {JSX.Element|*}
 * @constructor
 */
const Tooltip = ({ content, theme = 'light', delay = 200, children, ...props }) => {
  if (content === undefined || content === '') {
    return children;
  }

  return (
    <Tippy theme={theme} delay={delay} content={content} {...props}>
      {children}
    </Tippy>
  );
};

export default Tooltip;
