import { usePage } from '@inertiajs/inertia-react';
import { mdiClipboardListOutline, mdiFileOutline, mdiFolderOpenOutline, mdiFormTextbox, mdiGrid, mdiQrcodeScan } from '@mdi/js';
import * as Sentry from '@sentry/react';
import { FileBlankOutlineIcon, HomeOutlineIcon, ListCheckIcon, ListUlIcon, UserIcon } from 'cool-icons';
import ActionBar from 'Layouts/Partials/ActionBar';
import LeftNav from 'Layouts/Partials/LeftNav';
import MobileNav from 'Layouts/Partials/MobileNav';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import useInitialApplication from 'Support/Hooks/useInitialApplication.js';
import useRole from 'Support/Hooks/useRole.js';
import useUser from 'Support/Hooks/useUser.js';

const MainLayout = ({ children }) => {
  const { site_name } = useInitialApplication();
  const { flash, page_title } = usePage().props;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const permissions = useRole();
  const authenticatedUser = useUser();

  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, message }) => {
        toast(message, { type });
      });
    }
  }, [JSON.stringify(flash)]);

  const isActive = (...names) => names.some((name) => route().current(name, []));

  Sentry.setUser({
    id: authenticatedUser?.id,
    email: authenticatedUser?.email,
  });

  const navItems = [
    {
      title: 'Dashboard',
      href: route('dashboard'),
      icon: HomeOutlineIcon,
      active: isActive('dashboard'),
    },
    {
      title: 'Client Companies',
      href: route('admin.organizations.index'),
      icon: HomeOutlineIcon,
      filter: () => permissions.admin_show_organizations,
      active: isActive('admin.organizations.*'),
    },
    {
      title: 'Jobs',
      href: route('jobs.index'),
      icon: mdiFolderOpenOutline,
      filter: () => permissions.show_jobs,
      active: isActive('jobs.*'),
    },
    {
      title: 'Tasks',
      href: route(permissions.admin_show_tasks ? 'admin.tasks.index' : 'tasks.index'),
      icon: mdiClipboardListOutline,
      active: isActive('tasks.*', 'admin.tasks.*'),
    },
    {
      title: 'Task Analysis',
      href: route('hazard-reports.index'),
      icon: FileBlankOutlineIcon,
      filter: () => permissions.show_task_analysis,
      active: isActive('hazard-reports.*'),
    },
    {
      title: 'Task Analysis Templates',
      href: route('task-analysis-templates.index'),
      icon: ListUlIcon,
      filter: () => permissions.show_task_analysis_templates,
      active: isActive('task-analysis-templates.*'),
    },
    {
      title: 'Training Module Grid',
      href: route('training.grid'),
      icon: mdiGrid,
      filter: () => false,
      active: isActive('training.grid'),
    },
    {
      title: 'Form Templates',
      href: route(permissions.admin_show_forms ? 'admin.forms.index' : 'forms.index', {
        tab: permissions.admin_show_forms ? 'company-templates' : 'my-templates',
      }),
      icon: mdiFormTextbox,
      filter: () => permissions.show_forms,
      active: isActive('forms.*', 'admin.forms.*'),
    },
    {
      title: 'Visitor Logs',
      href: route('qr-form.index'),
      icon: mdiQrcodeScan,
      filter: () => permissions.show_visitor_logs,
      active: isActive('qr-form.*'),
    },
    {
      title: 'Forms',
      href: route(permissions.admin_show_reports ? 'admin.reports.index' : 'reports.index'),
      icon: mdiFormTextbox,
      active: isActive('reports.*', 'admin.reports.*'),
    },
    {
      title: 'Registers',
      href: route('hazards.index', { tab: 'my-hazards' }),
      icon: ListCheckIcon,
      filter: () => permissions.show_registers,
      active: isActive(
        'hazards.*',
        'ppe.*',
        'equipment.*',
        'checks.*',
        'hazardous-substances.*',
        'plant-and-equipment.*',
        'report-statuses.*',
        'training.index',
      ),
    },
    {
      title: 'Users',
      href: permissions.admin_show_users ? route('admin.users.index') : route('users.index'),
      icon: UserIcon,
      filter: () => permissions.show_users,
      active: isActive('admin.users.*', 'users.*'),
    },
    {
      title: 'Registers',
      href: route('admin.hazards.index'),
      icon: ListCheckIcon,
      filter: () => permissions.admin_show_registers,
      active: isActive(
        'admin.hazards.*',
        'admin.ppe.*',
        'admin.equipment.*',
        'admin.hazardous-substances.*',
        'admin.report-statuses.*',
        'admin.training.*',
      ),
    },
    {
      title: 'File Browser',
      href: route('s3.index'),
      icon: mdiFileOutline,
      active: isActive('s3.*'),
    },
    {
      title: 'My account',
      href: route('account.index'),
      icon: UserIcon,
      filter: () => permissions.show_account,
      active: isActive('account.*'),
    },
  ].filter((item) => typeof item.filter !== 'function' || item.filter());

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Helmet titleTemplate={`%s | ${site_name}`}>
        <title>{page_title || ' '}</title>
      </Helmet>

      <LeftNav items={navItems} />
      <MobileNav items={navItems} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex w-full flex-col overflow-auto">
        <div className="flex h-screen w-full flex-col overflow-x-hidden overflow-y-visible" scroll-region="true">
          <ActionBar setSidebarOpen={setSidebarOpen} />
          <main className="relative grow">{children}</main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
