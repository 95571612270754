import LeftNavFooter from 'Layouts/Partials/LeftNav/LeftNavFooter';
import LeftNavLogo from 'Layouts/Partials/LeftNav/LeftNavLogo';
import LeftNavMenu from 'Layouts/Partials/LeftNav/LeftNavMenu';
import { useLocalStorage } from 'react-use';

const LeftNav = ({ items }) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage('left-nav-collapsed', false);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="hidden bg-primary lg:flex">
      <aside className={`flex flex-col overflow-y-auto text-gray-300 transition-all duration-300 ease-in-out ${isCollapsed ? 'w-19' : 'w-75'}`}>
        <LeftNavLogo isCollapsed={isCollapsed} />
        <LeftNavMenu isCollapsed={isCollapsed} items={items} />
        <LeftNavFooter isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      </aside>
    </div>
  );
};

export default LeftNav;
