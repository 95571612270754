import { usePage } from '@inertiajs/inertia-react';

/**
 * @returns {App.Organization}
 */
export default () => {
  const {
    application: { organization },
  } = usePage().props;

  return organization;
};
