import Icon from '@mdi/react';
import cls from 'classnames';

const LeftNavIcon = ({ icon, active, subIcon = false }) => {
  const isReactComponent = typeof icon === 'function';
  const Component = isReactComponent ? icon : Icon;
  const props = isReactComponent ? {} : { path: icon, size: subIcon ? 0.666 : 1 };

  const classes = cls({
    'h-6 w-6': !subIcon,
    'h-4 w-4': subIcon,
    'flex-shrink-0': true,
    'text-white': active,
    'text-nav-icon': !active,
    'group-hover:text-white group-focus:text-white': true,
  });
  return <Component {...props} className={classes} />;
};

export default LeftNavIcon;
