import { Link } from '@inertiajs/inertia-react';
import cls from 'classnames';
import LeftNavLogoFull from './LeftNavLogoFull';
import LeftNavLogoSmall from './LeftNavLogoSmall';

const LeftNavLogo = ({ isCollapsed }) => (
  <Link href={route('dashboard')} className={cls(!isCollapsed && '-mb-5 mr-12 h-32', 'mx-6 mt-6 flex items-center transition-all duration-300')}>
    {isCollapsed ? (
      <LeftNavLogoSmall style={{ aspectRatio: '1 / 1' }} className="w-full" />
    ) : (
      <LeftNavLogoFull style={{ aspectRatio: '2 / 1' }} className="w-full object-contain" />
    )}
  </Link>
);

export default LeftNavLogo;
