import { Menu, Transition } from '@headlessui/react';
import cls from 'classnames';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import Portal from './Portal';

const MenuItemsWrapper = ({ children, show, trigger, grouped = false, width = 'w-36', padding = 'py-1' }) => {
  if (!trigger) return null;

  const [popper, setPopperElement] = useState();
  const { styles, attributes } = usePopper(trigger, popper, {
    placement: 'bottom-end',
    strategy: 'absolute',
  });

  return (
    <Portal>
      <div className={cls('z-10', width)} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Transition
          show={show}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items static className={cls('outline-none mt-2 divide-y divide-gray-100 rounded bg-white shadow-lg', width)}>
            {grouped ? children : <div className={padding}>{children}</div>}
          </Menu.Items>
        </Transition>
      </div>
    </Portal>
  );
};

export default MenuItemsWrapper;
