import { usePage } from '@inertiajs/inertia-react';

/**
 * @returns {App.Permissions|undefined}
 */
export default () => {
  const {
    application: { permissions },
  } = usePage().props;
  return permissions;
};
