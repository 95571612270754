import { Link } from '@inertiajs/inertia-react';
import Tooltip from 'Components/Tooltip';
import { LogOutIcon, ShortLeftIcon } from 'cool-icons';

const LeftNavFooter = ({ isCollapsed, toggleSidebar }) => {
  const itemClasses =
    'flex text-white rounded-md flex items-center tracking-tight px-4 py-3 leading-6 transition ease-in-out duration-150 focus:outline-none focus:ring';
  const itemTextClasses = `inline-block ml-3 whitespace-nowrap transition-opacity duration-300 ease-in-out ${isCollapsed ? 'opacity-0' : 'opacity-100'}`;

  return (
    <div className={`mt-auto flex items-start px-2 pb-8 text-sm ${isCollapsed ? 'flex-col space-y-8' : 'justify-between'}`}>
      <Tooltip content="Log out" disabled={!isCollapsed} placement="right">
        <Link href={route('logout')} className={itemClasses}>
          <LogOutIcon className="h-6" />
          {!isCollapsed && <span className={itemTextClasses}>Log Out</span>}
        </Link>
      </Tooltip>

      <Tooltip content={isCollapsed ? 'Expand' : 'Collapse'} placement="right">
        <button onClick={toggleSidebar} className={itemClasses}>
          <ShortLeftIcon className={`h-6 text-white transition-transform duration-700 ease-in-out ${isCollapsed ? 'rotate-180' : ''}`} />
        </button>
      </Tooltip>
    </div>
  );
};

export default LeftNavFooter;
