import { Menu } from '@headlessui/react';
import { Link } from '@inertiajs/inertia-react';
import cls from 'classnames';

/**
 * @param children
 * @param href
 * @param icon
 * @param as
 * @param disabled
 * @param itemProps
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = ({ children, href, icon, as = Link, ...itemProps }) => (
  <div className="py-1">
    <Menu.Item {...itemProps}>
      {({ active, disabled }) => {
        const ElementTag = disabled ? 'button' : as;
        const IconTag = icon || 'div';

        const elementProps = {};
        if (typeof href !== 'undefined') {
          elementProps.href = href;
        }

        if (disabled) {
          delete elementProps.href;
          elementProps.disabled = true;
        }

        if (ElementTag === 'button') {
          elementProps.type = 'button';
        }

        return (
          <ElementTag
            {...elementProps}
            className={cls({
              'bg-gray-100 text-gray-900': active,
              'text-gray-900': !active && !disabled,
              'cursor-default text-gray-700': disabled,
              'focus:outline-none focus:bg-gray-100 focus:text-gray-900': !disabled,
              'flex w-full items-center space-x-3 px-4 py-2 text-left text-sm leading-5': true,
            })}
          >
            {icon && <IconTag className="h-5 w-5 shrink-0 text-gray-700" />}
            <span className="truncate">{children}</span>
          </ElementTag>
        );
      }}
    </Menu.Item>
  </div>
);

export default MenuItem;
