import { usePage } from '@inertiajs/inertia-react';

/**
 * @returns {App.User|undefined}
 */
export default () => {
  const {
    application: { user },
  } = usePage().props;

  return user;
};
