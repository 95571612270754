import { App } from '@inertiajs/inertia-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import MainLayout from 'Layouts/MainLayout';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createElement } from 'react';
import { render } from 'react-dom';
import { ToastContainer } from 'react-toastify';

import 'Style/app.css';
import { FormErrorContextProvider } from 'Support/Contexts/FormErrorContext';
import { InitialApplicationContext } from 'Support/Contexts/InitialApplicationContext';
import 'Support/yupExtensions';

const app = document.getElementById('app');
const initialPage = window.inertiaInitialPageData;

if (window.sentryDsn) {
  Sentry.init({
    dsn: window.sentryDsn,
    integrations: [new BrowserTracing()],
    environment: window.sentryEnvironment,
    tracesSampleRate: window.sentryTracesSampleRate,
  });
}

render(
  <InitialApplicationContext.Provider value={initialPage.props.application}>
    <App
      initialPage={initialPage}
      resolveComponent={async (name) => (await resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'))).default}
    >
      {({ key, props, Component }) => {
        const page = createElement(Component, { key, ...props });
        let children;

        if (typeof Component.layout === 'function') {
          children = Component.layout(page);
        } else if (Array.isArray(Component.layout)) {
          children = Component.layout
            .concat(page)
            .reverse()
            .reduce((children, Layout) => createElement(Layout, { children }));
        } else {
          children = <MainLayout children={page} />;
        }

        return (
          <FormErrorContextProvider>
            {children}
            <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover position="top-right" />
          </FormErrorContextProvider>
        );
      }}
    </App>
  </InitialApplicationContext.Provider>,
  app,
);
